import '../utils/styles.css';

function View() {
  return (
    <div className="App">
     Adhunik Radio
    </div>
  );
}

export default View;
